import React from 'react'
import { graphql, Link } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { Row, Col } from "react-bootstrap"
import Util from "@components/core/util"
import PostCard from "@templates/post-card.js"
import PostCardSummerized from "@templates/post-card-summerized.js"
import '@styles/index.scss'
// import PostCardList from "@templates/post-card-list"
const isHome = true
const isPost = false
const linkKey = 'home'
const isMenu = true

const Home = (props) => {
  return (
  <Layout>
    <SEO 
      title="블로그"
      isHome={isHome}
      isPost={isPost}
      linkKey={linkKey}
      isMenu={isMenu}
      schemaOrgJSONLD={props.jsonLd} />
    <div className="menu-page home">
      {
        props.homeCardList.map((card, index) => (
          <div
            key={index}
            className={ 'menu-category '  + (card.summerized ? 'summerized' : '')}>
            <div className="header">
              <span className="title">{card.title}</span>
              {
                (!card.summerized && card.urlLink.length === 1) &&
                  <Link 
                    className="btn-more"
                    to={card.urlLink[0].path}>
                    <span className="text">더보기</span><span className="ic ic-arrow-left"></span>
                  </Link>
              }
              {
                (!card.summerized && card.urlLink.length > 1) &&
                  <span className="btn-more cursor-pointer">
                    <span className="text">더보기</span><span className="ic ic-arrow-left"></span>
                    <span className="popover-list">
                      {
                        card.urlLink.map((link, index) => (
                          <Link
                            key={index}
                            className="btn-more"
                            to={link.path}>
                              <span className="text">{link.label}</span><span className="ic ic-arrow-left"></span>
                          </Link>
                        ))
                      }
                    </span>
                  </span>
              }
            </div>
            <Row className={ "post-card-list " + (card.list.length === 0 ? 'no-data' : '')}>
              {
                card.list.map((cardItem, index) => {
                  if (card.summerized) {
                    return (
                      <Col 
                        className="post-card-list-item"
                        xs="12"
                        md="4"
                        lg={card.lg}
                        key={index}>
                        <PostCardSummerized
                          post={cardItem}/>
                      </Col>
                    )
                  } else {
                    return (
                      <Col 
                        className="post-card-list-item"
                        xs="12"
                        md="6"
                        lg={card.lg}
                        key={index}>
                        <PostCard
                          post={cardItem}/>
                      </Col>
                    )
                  }
                  
                })
              }
            </Row>
            <div className="division-line"></div>
          </div>
        ))
      }
    </div>
  </Layout>
)
}

export default ({ data }) => {
  let homeCardList = []
  const homeInfo = {
    recommend: { order: 0, lg: 4, title: '빌드잇 추천 글', list: [], summerized: true },
    '/team': { order: 4, urlLink: [{ path: '/team' }], lg: 6, title: '팀 최신 글', list: [], summerized: false  },
    '/tech': { order: 3, urlLink: [{ path: '/tech' }], lg: 6, title: '기술 최신 글', list: [], summerized: false  },
    // [컨텐츠 생성 전]
    // '/buildthing': { order: 2, urlLink: [{ path: '/tutorials', label: '튜토리얼' }, { path: '/cases', label: '사용/구축 사례' }], lg: 6, title: 'BuildThing 최신 글', list: [], summerized: false  },
    '/news': { order: 1, urlLink: [{ path: '/news' }], lg: 6, title: '뉴스 최신 글', list: [], summerized: false  }
  }
  
  const posts = Util.getAllPosts(data)
  posts.forEach((post) => {
    if (post.isRecommended) {
      if (homeInfo.recommend.list.length < 3) homeInfo.recommend.list.push(post)
    }
    let target = homeInfo[post.menu.urlLink]
    if (post.menu.parent) {
      target = homeInfo[post.menu.parent.urlLink]
    }
    if (target) {
      if (target.list.length < 2) {
        if (post.isRecentPost) {
          target.list.push(post)
        }
      }
    }
  })

  homeCardList = Object.values(homeInfo)
  homeCardList.sort((a, b) => {
    return a.order - b.order
  })
  // console.log(homeCardList)

  let params = homeCardList[0].list.concat(homeCardList[1].list)
  let jsonLd = Util.getJsonLDFormatted(params)
  
  return Home({ homeCardList, jsonLd })
}

export const query = graphql`
query {
  blog {
    allPosts(sort: DATE_DESC) {
      edges {
        node {
          id
          title
          thumbnailPath
          menuId
          menu {
            id
            name
            urlLink
            parent {
              id
              name
              urlLink
            }
          }
          categoryId
          category {
            id
            menuId
            name
          }
          isRecommended
          isRecentPost
          date
          desc
        }
      }
    }
  }
}
`