import React from 'react'
import Moment from 'moment'
import { Link } from "gatsby"
import { Card } from "react-bootstrap"
import "@styles/templates/post-card-summerized.scss"
// import "@styles/templates/post-card.scss"

const CardItem = function (post) {
  this.id = post.id
  this.title = post.title
  this.thumbnailPath = post.thumbnailPath
  this.categoryName = post.category.name
  this.desc = post.desc
  this.date = Moment(post.date).format('YYYY-MM-DD')
}

const PostCardSummerized = (props) => {
  let post = props.post
  let cardItem = new CardItem(post)
  let postPath = '/post/' + cardItem.id
    
  return (
      <Card
        className="post-card-summerized">
          <Card.Img
            variant="top"
            src={cardItem.thumbnailPath}/>
          <Card.Body>
              <div className="category-name">{cardItem.categoryName}</div>
              <Card.Title
                className="text-ellipsis-2">
                  {cardItem.title}
              </Card.Title>
          </Card.Body>
          <Link 
            to={postPath}
            className="dummy-link-layer"></Link>
      </Card>
  )
}

export default PostCardSummerized